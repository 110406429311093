import { useContext } from 'react'
import type { loader as RootLoader } from '~/root'

import {
  ArchiveBoxIcon,
  ArrowRightEndOnRectangleIcon,
  ArrowRightStartOnRectangleIcon,
  Cog8ToothIcon,
  CreditCardIcon,
  LightBulbIcon,
  PencilSquareIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  TicketIcon,
  UserIcon,
  UserPlusIcon,
} from '@heroicons/react/16/solid'
import { Avatar } from '~/components/ui/avatar'
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from '~/components/ui/dropdown'
import {
  Navbar,
  NavbarItem,
  NavbarSection,
  NavbarSpacer,
} from '~/components/ui/navbar'

import {
  Link,
  isRouteErrorResponse,
  useParams,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react'

import { SearchPaletteContext } from '../contexts/SearchContext'

import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { shouldHideFeature, shouldShowFeature } from '~/config/features'
import { getSupportUrl } from '~/utils/utils'
import LoungePairLogo from './svg-logos/LoungePairLogo'
import { accountHasRole } from '~/utils/accounts'

export function Header() {
  if (shouldHideFeature('nav')) return false
  const params = useParams()
  const error = useRouteError()
  const root = useRouteLoaderData<typeof RootLoader>('root')
  const notLoggedIn = isRouteErrorResponse(error) || Boolean(!root?.account)
  const isVerified = Boolean(root?.account && root?.account?.roles.length > 0)
  const canEdit = accountHasRole(root?.account, 'LP_ADMIN')
  const { setIsOpen } = useContext(SearchPaletteContext)

  // These have been implemented
  const shouldEdit = params.iata || params.slug
  function handleEdit(): string {
    if ('slug' in params) {
      return `/admin/resources/lounges?page=1&filters.slug=${params.slug}`
    }
    if ('iata' in params) {
      // edit airport
      return `/admin/resources/airports?page=1&filters.iata=${params.iata}`
    }
    if ('id' in params) {
      // edit order
    }
    return '/admin'
  }

  return (
    <Navbar className="mb-6 mx-4 grid grid-cols-12">
      <NavbarSpacer className="col-span-3" />
      <Link
        to="/directory/"
        aria-label="Home"
        className="flex justify-center col-span-6"
      >
        {/*
        {root?.host?.includes('etihad') ? (
        <>
          <EtihadLogo className="w-40 m-auto mb-2 sm:mb-6 sm:w-48 text-stone-800" />
          <div className="flex items-center justify-center gap-3 mb-6 text-sm italic sm:mb-10 text-stone-800">
            <span>Powered by</span>
            <LoungePairLogo className="w-24" />
          </div>
        </>)
        */}
        <LoungePairLogo className="w-36 sm:w-48 text-stone-800" />
      </Link>
      <NavbarSection className="justify-end col-span-3">
        {canEdit && shouldEdit ? (
          <NavbarItem
            target="_blank"
            href={handleEdit()}
            aria-label="Edit Page"
          >
            <PencilSquareIcon
              className="w-5 h-5 text-stone-800"
              aria-hidden="true"
            />
          </NavbarItem>
        ) : null}

        {shouldShowFeature('nav.search') ? (
          <NavbarItem onClick={() => setIsOpen(true)} aria-label="Edit Page">
            <MagnifyingGlassIcon
              className="w-5 h-5 text-stone-800"
              aria-hidden="true"
            />
          </NavbarItem>
        ) : null}
        {shouldShowFeature('nav.accounts') ? (
          <AccountMenu isVerified={isVerified} notLoggedIn={notLoggedIn} />
        ) : null}
      </NavbarSection>
    </Navbar>
  )
}

export function AccountMenu({
  notLoggedIn,
  isVerified,
}: { notLoggedIn: boolean; isVerified: boolean }) {
  const supportUrl = getSupportUrl()

  if (notLoggedIn) {
    return (
      <Dropdown>
        <DropdownButton as={NavbarItem} aria-label="Account menu">
          <span className="inline-block h-6 w-6 overflow-hidden rounded-full bg-stone-50 cursor-pointer">
            <svg
              className="h-full w-full text-stone-600"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </span>
        </DropdownButton>
        <DropdownMenu className="min-w-64" anchor="bottom end">
          <DropdownItem href="/account/login">
            <ArrowRightEndOnRectangleIcon />
            <DropdownLabel>Travel Partner Login</DropdownLabel>
          </DropdownItem>
          <DropdownItem href="/account/signup">
            <UserPlusIcon />
            <DropdownLabel>Apply as a Travel Partner</DropdownLabel>
          </DropdownItem>
          <DropdownDivider />
          <DropdownItem href="https://www.loungepair.com/privacy-policy">
            <ShieldCheckIcon />
            <DropdownLabel>Privacy policy</DropdownLabel>
          </DropdownItem>
          <DropdownItem href="https://helpdesk.loungepair.com/help/3983236506">
            <LightBulbIcon />
            <DropdownLabel>Share feedback</DropdownLabel>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  }

  return (
    <Dropdown>
      <DropdownButton as={NavbarItem} aria-label="Account menu">
        {/* <Avatar initials={root.account.initials} alt="User avatar" /> */}
        <span className="inline-block h-6 w-6 overflow-hidden rounded-full bg-stone-50">
          <svg
            className="h-full w-full text-stone-600"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </span>
      </DropdownButton>
      <DropdownMenu className="min-w-64" anchor="bottom end">
        <DropdownItem href="/account/profile">
          <UserIcon />
          <DropdownLabel>
            {isVerified ? 'My Profile' : 'My Application'}
          </DropdownLabel>
        </DropdownItem>
        {isVerified ? (
          <>
            <DropdownItem href="/account/orders">
              <TicketIcon />
              <DropdownLabel>Orders</DropdownLabel>
            </DropdownItem>
            <DropdownItem href="/account/billing">
              <CreditCardIcon />
              <DropdownLabel>Billing</DropdownLabel>
            </DropdownItem>
            {/* <DropdownItem href="/account/settings">
            <Cog8ToothIcon />
            <DropdownLabel>Settings</DropdownLabel>
          </DropdownItem> */}
          </>
        ) : (
          <DropdownItem href="/account/orders">
            <TicketIcon />
            <DropdownLabel>Orders</DropdownLabel>
          </DropdownItem>
        )}
        <DropdownDivider />
        <DropdownItem rel="nofollow" href={supportUrl}>
          <QuestionMarkCircleIcon />
          <DropdownLabel>Get Support</DropdownLabel>
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem href="/account/logout">
          <ArrowRightStartOnRectangleIcon />
          <DropdownLabel>Log out</DropdownLabel>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}
